.advert-preview-modal-container {
  font-family: Poppins, Helvetica, 'sans-serif';
  height: 100%;
  width: 100%;

  h6 {
    font-size: 13px;
    font-weight: 500;
  }

  b {
    color: #1E1E2D;
    font-weight: 600;
  }

  .preview-modal-scroll-container {
    padding-top: 30px;
  }

}

.text-bold {
  font-weight: 600;
}

.price-input-wrap {
  text-align: end;
}

.tab-wrapper {
  font-size: 20px; 
  cursor: pointer;
}

.map-content-warp {
  max-height: 91px;
  margin-top: 5px !important;
}

.border-wrap {
  border-top: 4px solid #EFF2F5;
  border-bottom: 5px solid #EFF2F5;
  border-left: 5px solid #EFF2F5;
  border-right: 5px solid #EFF2F5;
}

.fromDetailsWrap {
  padding-right: 20px;
  margin-left: 10px;
}

.toDetailsWrap {
  padding-right: 20px;
  margin-left: 10px;
}

.create-map-view-popup-wrap { 
  width: 58% !important;
}

@media screen and (max-width: 950px) {

  .create-map-view-popup-wrap {
    width: 100% !important;
  }

  .fromDetailsWrap {
    padding: 0;
    margin-bottom: 10px;
    margin-right: 10px;
  }

  .distance-wrap {
    width: 55% !important;
  }

  .btn-distance-wrap {
    width: 40%;
    padding: 10px;
  }

  .toDetailsWrap {
    padding: 0;
    margin-bottom: 10px;
    margin-right: 10px;
  }
}
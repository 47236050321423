.main-public-financial-wrap {
    padding: 40px 80px;
    overflow: auto;
  }

  .invoice-btn-wrap {
    border: 1px solid #009EF7;
    padding: 5px;
    border-radius: 10px;
  }

  .btn-wrap-content {
    font-size: 16px !important;
    color: #c4c4c4 !important;
  }

  .btn-wrap-content:hover {
    color: black !important;
  }

  .btn-active-content {
    color: #ffffff !important;
  }

  .btn-active-content:hover {
    color: #ffffff !important;
  }

  .no-data-content-wrap {
    display: contents;
  }
.custom-menu-arrow {
    &:hover, &:focus, &:active {
        .menu-arrow-custom {
            border-color: #009EF7;
        }
    }
}
.menu-arrow-custom {
    display: flex;
    align-items: stretch;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    margin-left: 0.5rem;
    width: 0.6rem;
    height: 0.6rem;
    border-right: 0.2em solid #7E8299;
    border-top: 0.2em solid #7E8299;
    transform: rotate(135deg);
    margin-bottom: 5px;
}

.font-action-title {
    font-size: 13px;
}

.action-menu-wrap {
    display: flex;
    justify-content: center;
}
.registration-content-wrap {
    padding: 0px 300px;
    .tab-manager-main {
        padding: 20px;
    }
}

.phoneInp-wrap {
    max-width: 75%;
}

.registration-content-tab-wrap {
    padding-bottom: 1.5rem !important;
}

.login-main-content-wrap {
    width: auto !important;
    min-width: 514px !important;
}

.eye-border-wrap {
    border: 1px solid !important;
}

.eye-wrap {
    width: 16px;
    font-size: 18px !important;
    color: #009EF7;
}

.login-modal-wrap {
    max-height: 700px !important;
}

@media screen and (max-width: 950px) {
    .registration-content-wrap {
        padding: 0px 30px;

        .tab-manager-main {
            padding: 0;
        }
    }

    .phone-Inp-mobile-wrap {
        flex: 0 0 auto;
        width: 44% !important;
    }

    .mobile-Inp-mobile-wrap {
        flex: 0 0 auto;
        width: 68% !important;
        margin-left: -44px !important;
    }

    .mobile-tab-wrapper {
        font-size: 18px !important;
    }
}
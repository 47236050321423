.advert-preview-modal-container {
  font-family: Poppins, Helvetica, 'sans-serif';
  height: 100%;
  width: 100%;

  h6 {
    font-size: 13px;
    font-weight: 500;
  }

  b {
    color: #1E1E2D;
    font-weight: 600;
  }

  .preview-modal-scroll-container {
    padding-top: 30px;
  }

}

.consignor-wrap {
  padding-right: 15px;
}

.consignee-wrap {
  padding-left: 15px;
}

.danger-text {
  color: #DC3444;
}

.text-bold {
  font-weight: 600;
}

.price-input-wrap {
  text-align: end;
}

.main-error-wrapper {
  width: 100%; 
  text-align: center; 
  border-bottom: 1px solid #bec1c8; 
  line-height: 0.1em;
  margin: 10px 0 20px; 
}

.main-error-wrapper span {
  background:#f5f8fa; 
  padding:0 10px;
}

.active-tab-wrap {
  border-bottom: 1px solid #3699FF !important;
}

.nav-normal-wrap {
  color: black !important;
}

.nav-main-wrap {
  display: block;
  padding: 0.5rem 1rem;
}

.create-new-address-wrap {
  width: 58% !important;
}

@media screen and (max-width: 950px) {
  .create-new-address-wrap {
    width: 100% !important;
  }
}
$primary: #339ef7;
$text-color: #363738;

.date__box {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ccc;
  border: 4px solid;
  font-weight: bold;
  padding: 5px 10px;
  .date__day{
    font-size: 22px;
  }
}
.blog-card {
  padding: 30px;
  position: relative;
  .date__box {
    opacity: 0;
    transform: scale(0.5);
    transition: 300ms ease-in-out;
  }
  .blog-card__background,
  .card__background--layer{
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .blog-card__background {
    padding: 15px;
    background: white;
  }
  .card__background--wrapper{
    height: 100%;
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0 60%);
    position: relative;
    overflow: hidden;
  }
  .card__background--main{
    height: 100%;
    position: relative;
    transition: 300ms ease-in-out;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .card__background--layer {
    z-index: 0;
    opacity: 0;
    background: rgba(#333, 0.9);
    transition: 300ms ease-in-out;
  }
  .blog-card__head {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .blog-card__info{
    z-index: 10;
    background: white;
    padding: 20px 15px;
    h5 {
      transition: 300ms ease-in-out;
    }
  }
  &:hover {
    .date__box{
      opacity: 1;
      transform: scale(1);
    }
    .card__background--main {
      transform: scale(1.2) rotate(5deg);
    }
    .card__background--layer {
      opacity: 1;
    }
    .blog-card__info{
      h5{
        color: $primary; 
      }
    }
  }

  a.icon-link {
    color: $text-color;
    transition: 200ms ease-in-out;
    i {
      color: $primary;
    }
    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  .btn {
    background: white;
    color: $text-color !important;
    font-weight: bold !important;
    outline: none !important;
    box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.2) !important;
    overflow: hidden;
    border-radius: 0 !important;
    height: 50px;
    line-height: 50px !important;
    display: inline-block !important;
    padding: 0 !important;
    border: none !important;
    &:focus {
      box-shadow: none !important;
    }
    &:hover {
      background: $primary !important;
      color: #fff !important;
    }
    &.btn--with-icon {
      padding-right: 20px !important;
      i {
        padding: 0px 30px 0px 15px !important;
        margin-right: 10px !important;
        height: 50px !important;
        line-height: 50px !important;
        vertical-align: bottom !important;
        color: white !important;
        background: $primary !important;
        clip-path: polygon(0 0, 70% 0, 100% 100%, 0% 100%) !important;
      }
    }
    &.btn--only-icon {
      width: 50px !important;
    }
  }
}

.blog_main_title_wrap > h1 {
    font-size: 50px;
    color: #757575;
    font-size: 72px;
    line-height: 84px;
}

.related_post_title {
    color: #000;
    font-family: Roboto, Sans-serif;
    font-size: 40px;
    font-weight: 600;
}

.related_post_desc {
  color: #4E4E4E;
  text-align: center;
    font-family: Roboto, Sans-serif;
    font-size: 26px;
    font-weight: 400;
}

.related-page-nav-wrap {
  padding-top: 0;
  background-color: transparent;
}

.read-more-wrap {
  text-transform: uppercase;
}

@media screen and (max-width: 560px) {
  .blog_mobile_wrap {
    display: flex;
    width: 100% !important;
  }

  .blog-card {
    margin: 0 10px;
  }
  
}
.advert-detail-modal-container {
  font-family: Poppins, Helvetica, 'sans-serif';
  height: 100%;
  width: 100%;

  h6 {
    font-size: 13px;
    font-weight: 500;
  }

  b {
    color: #1E1E2D;
    font-weight: 600;
  }

  .detail-modal-scroll-container {
    margin-top: 40px;
    height: 92%;
    width: 100%;
  }

}

.freightIconWrap {
  width: 100%;
  max-width: 55px;
}

.publish-group-wrap {
  .optionListContainer {
    z-index: 99;
  }
}

.right-layout-main-show-wrap {
  width: 41% !important;
  right: 0;
  left: auto;
  background-color: white;
}

.text-bold {
  font-weight: 600;
}

.edit-wrap-address {
  padding-right: 10px;
}

.edit-wrap-address-left {
  padding-left: 10px;
}

.action-menu-wrapper {
  .action-menu-wrap {
    display: flex;
    justify-content: end;
  }
}

.right-layout-show-wrap {
  width: 50% !important;
}

.freight-publish-date-wrap {
  & > div {
    justify-content: flex-start !important;
  }
}

.right-layout-sub-show-wrap {
  width: 100% !important;
  border: 3px solid #ecf0f2;
}

.next-btn-publish-freight-wrap {
  .next-btn-disabled {
    opacity: 0.5;
  }
}

@media screen and (max-width: 950px) {
  .right-layout-show-wrap {
    width: 100% !important;
  }

  .edit-wrap-address {
    padding-right: 0;
  }

  .edit-wrap-address-left {
    padding-left: 0;
  }
}

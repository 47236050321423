.main-content-home-wrap {
    --blue: #339ef7;
  --blue5: rgba(51, 158, 247, 0.05);
  --blue10: rgba(51, 158, 247, 0.1);
  --blue15: rgba(51, 158, 247, 0.15);
  --blue40: rgba(51, 158, 247, 0.4);
  /* Netral Color */
  --black: #000000;
  --Dark-gray: #1e1e1e;
  --gray-600: #686868;
  --gray-500: #8f8f8f;
  --gray-400: #c4c4c4;
  --gray-300: #d9d9d9;
  --gray-200: #ebebeb;
  --gray-100: #f5f5f5;
  --White: #ffffff;

  --darkskyblue: #4585bb;
  --green: #30c681;
  --purple: #5369af;
  --gray900: #2c2c2c;
  /* transition */
  --fast-transition: 0.15s;
  --slow-transition: 0.5s;

  overflow: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0 !important;
  padding: 0 !important;
}

.container-fluid {
    --bs-gutter-x: 2rem;
  }
  
  .darkskyblue {
    background: var(--darkskyblue) !important;
  }
  
  .green {
    background: var(--green) !important;
  }
  
  .purple {
    background: var(--purple) !important;
  }
  
  .gray900 {
    background: var(--gray900) !important;
  }
  
  /* button start */
  .primary-button {
    border: 0.063rem solid var(--blue);
    border-radius: 0.375rem;
    display: inline-flex;
    align-items: center;
    height: 3rem;
    padding: 0 0.875rem;
    gap: 0.625rem;
    background: var(--blue);
    font-size: 0.875rem;
    line-height: 0.875rem;
    font-weight: 500;
    color: var(--White);
    outline: none;
    justify-content: center;
  }
  
  .primary-button span {
    width: 1rem;
    height: 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  .primary-button span img {
    width: 100%;
    height: 100%;
  }
  
  .primary-button-36 {
    border: 0.063rem solid var(--blue);
    border-radius: 0.375rem;
    display: inline-flex;
    align-items: center;
    height: 2.25rem;
    padding: 0 0.875rem;
    gap: 0.625rem;
    background: var(--blue);
    font-size: 0.875rem;
    line-height: 0.875rem;
    font-weight: 500;
    color: var(--White);
    outline: none;
    justify-content: center;
  }
  
  .primary-button-36 span {
    width: 1rem;
    height: 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  .primary-button-36 span img {
    width: 100%;
    height: 100%;
  }
  
  .secondary-button {
    border: 0.063rem solid var(--blue);
    border-radius: 0.375rem;
    display: inline-flex;
    align-items: center;
    height: 3rem;
    padding: 0 0.875rem;
    gap: 0.625rem;
    background: var(--White);
    font-size: 0.875rem;
    line-height: 0.875rem;
    font-weight: 500;
    color: var(--blue);
    outline: none;
    justify-content: center;
  }
  
  .secondary-button span {
    width: 1rem;
    height: 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  .secondary-button span img {
    width: 100%;
    height: 100%;
  }
  
  .secondary-button-36 {
    border: 0.063rem solid var(--blue);
    border-radius: 0.375rem;
    display: inline-flex;
    align-items: center;
    height: 2.25rem;
    padding: 0 0.875rem;
    gap: 0.625rem;
    background: var(--White);
    font-size: 0.875rem;
    line-height: 0.875rem;
    font-weight: 500;
    color: var(--blue);
    outline: none;
  }
  
  .secondary-button-36 span {
    width: 0.875rem;
    height: 0.875rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  .secondary-button-36 span img {
    width: 100%;
    height: 100%;
  }
  
  /* button end */
  .darkgray-tag {
    background: var(--gray-600);
    color: var(--White);
    height: 1.5rem;
    padding: 0 0.438rem 0 1rem;
    font-size: 0.85rem;
    font-weight: 600;
    text-align: right;
    text-transform: uppercase;
    position: relative;
    display: inline-flex;
    align-items: center;
    width: max-content;
  }
  
  .darkgray-tag:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 0.55rem solid white;
    border-top: 0.75rem solid transparent;
    border-bottom: 0.75rem solid transparent;
  }
  
  /* dropdown button start */
  .dropdown-style1 button {
    width: 2rem;
    height: 2rem;
    background: var(--White) !important;
    border: 0.063rem solid var(--gray-300) !important;
    border-radius: 0.375rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  .elipsis-wrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .filter-span-wrap {
    background: white !important;
    border: 0.063rem solid var(--gray-300) !important;
    padding: calc(0.15rem + 1px) calc(0.7rem + 1px) !important;
  }

  .filterEnabled {
    background-color: #B5B5C3 !important;
  }

  .filterdisabled {
    background-color: #ffffff !important;
  }
  
  .dropdown-style1 button:hover {
    background: var(--White);
    border: 0.063rem solid var(--gray-300);
  }
  
  .dropdown-style1 button:focus {
    background: var(--White);
    border: 0.063rem solid var(--gray-300);
  }
  
  .dropdown-style1 .show {
    background: var(--White);
    border: 0.063rem solid var(--gray-300);
  }
  
  .dropdown-style1 button img {
    width: auto;
  }
  
  .dropdown-style1 button::after {
    display: none;
  }

  .no-announcement-main {
    height: 100%;

    .no-announcement-content {
      height: 100%;
      max-height: 100%;
    }
  }
  
  /* dropdown button end */
  /* table-area start */
  .table-area table tr {
    border-bottom: 1px solid #dee2e6 !important;
  }
  .table-area table tr td {
    vertical-align: middle;
    padding: .5rem .5rem !important;
  }
  
  .table-area table tr td p {
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    color: var(--black) !important;
  }
  
  .table-area table tr td span {
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    color: var(--black) !important;
  }
  
  .table-area table tr:nth-of-type(odd)>* {
    background: var(--blue5) !important;
    --bs-table-bg-type: none !important;
  }
  
  /* table-area end */
  
  /* header-area start */
  .header-area {
    padding: 0.875rem 0;
    box-shadow: 0px 0.25rem 0.625rem 0px rgba(0, 0, 0, 0.05);
    border-bottom: 0.063rem solid var(--gray-400);
    position: fixed;
    width: 100%;
    background: var(--White);
    left: 0;
    top: 0;
    z-index: 100;
  }
  
  .header-area .navbar-collapse {
    flex-grow: 0;
  }
  
  .header-area .navbar {
    padding: 0;
  }
  
  .header-button-area {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .navbar-brand {
    width: 10.313rem;
  }
  
  .navbar-brand img {
    width: 100%;
  }
  
  /* header-area end */
  
  .db-body-area {
    padding: 4.875rem 0 1rem 0;
    background: #f1f1f1;
  }
  
  .db-body-area-inner {
    padding: 1rem 0 0 0;
  }
  
  .db-body-area-inner .row {
    --bs-gutter-x: 1rem;
    --bs-gutter-y: 1rem;
  }
  
  .db-card {
    background: var(--White);
    border-radius: 0.375rem;
    border: 0.063rem solid var(--gray-400);
  }
  
  /* db-cardstyle-1 start */
  .db-cardstyle-1 {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .db-cardstyle-1 .db-card-header {
    padding: 1rem;
    display: flex;
    align-items: center;
    border-bottom: 0.063rem solid var(--gray-400);
  }
  
  .db-cardstyle-1 .db-card-header .left {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 85%;
  }
  
  .db-cardstyle-1 .db-card-header .left .icon {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: var(--blue15);
    border: 0.063rem solid var(--blue40);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .db-cardstyle-1 .db-card-header .left .icon img {
    filter: brightness(0) saturate(100%) invert(57%) sepia(55%) saturate(3494%) hue-rotate(184deg) brightness(100%) contrast(94%);
    width: 60%;
  }
  
  .db-cardstyle-1 .db-card-header .left h3 {
    color: var(--black);
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    width: 80%;
  }
  
  .db-cardstyle-1 .db-card-header .dropdown {
    margin-left: auto;
  }
  
  .db-cardstyle-1 .db-card-content {
    padding: 1rem;
  }
  
  .db-cardstyle-1 .db-card-content p {
    font-size: 0.875rem !important;
    line-height: 1.75rem !important;
    color: var(--gray-600);
  }
  
  .db-cardstyle-1 .db-card-footer {
    padding: 1rem;
    margin-top: auto;
  }
  
  /* db-cardstyle-1 end */
  .map {
    height: 15.8rem;
    overflow: hidden;
  }
  
  .map img {
    cursor: pointer;
    width: 100%;
  }
  
  .Vehicle-buyselling-area {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .Vehicle-buyselling-area-item {
    display: flex;
    align-items: center;
    padding: 1rem;
    border: 0.063rem solid var(--gray-300);
    border-radius: 0.375rem;
    gap: 6rem;
  }
  
  .Vehicle-buyselling-img {
    height: 5.25rem;
    width: 6.313rem;
    border-radius: 0.375rem;
    overflow: hidden;
  }
  
  .Vehicle-buyselling-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .Vehicle-buyselling-info {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    width: 63%;
    flex-wrap: wrap;
    gap: 0 1rem;
  }
  
  .Vehicle-buyselling-info li {
    width: 30.333%;
    border-bottom: 0.063rem solid var(--gray-300);
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  .Vehicle-buyselling-info li:nth-last-child(1),
  .Vehicle-buyselling-info li:nth-last-child(2),
  .Vehicle-buyselling-info li:nth-last-child(3) {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  
  .Vehicle-buyselling-info li h6 {
    font-size: 0.75rem;
    color: var(--gray-500);
    font-weight: normal;
    line-height: 1rem !important;
  }
  
  .Vehicle-buyselling-info li p {
    font-size: 0.75rem !important;
    color: var(--black);
    font-weight: 500;
    line-height: 1rem !important;
  }
  
  .Vehicle-buyselling-button-area {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    gap: 1rem;
  }
  
  
  .user-announcements-list-area {
    border: 0.063rem solid var(--gray-300);
    border-radius: 0.375rem;
    max-height: 19.1rem;
    overflow-y: auto;
  }

  .content-disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .cursor-banned {
    cursor: not-allowed;
  }
  
  /* width */
  .user-announcements-list-area::-webkit-scrollbar {
    width: 0.313rem;
  }
  
  /* Track */
  .user-announcements-list-area::-webkit-scrollbar-track {
    background: var(--gray-200);
  }
  
  /* Handle */
  .user-announcements-list-area::-webkit-scrollbar-thumb {
    background: var(--blue);
  }
  
  .user-announcements-item {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    padding: 0.938rem 0.625rem;
    border-bottom: 0.063rem solid var(--gray-300);
  }
  
  .user-announcements-item:last-child {
    border-bottom: 0;
  }
  
  .user-announcements-item:nth-child(even) {
    background: var(--blue10);
  }
  
  .user-announcements-item .icon {
    width: 2.75rem;
    height: 2.75rem;
    border-radius: 50%;
    border: 0.063rem solid var(--gray-300);
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  .user-announcements-item .icon img {
    width: 60%;
  }
  
  .user-announcements-item-right {
    width: 90%;
  }
  
  .tooltip-custom-wrap {

    .tooltip-inner {
      padding: 15px;
    width: 100%;
    max-width: 300px !important;
    }
  }

  .user-announcements-item-right h4 {
    font-size: 0.875rem;
    color: var(--black);
    font-weight: 500;
  }
  
  .user-announcements-item-right p {
    font-size: 0.688rem !important;
    line-height: 1rem !important;
    color: var(--gray-600);
    font-weight: 500;
  }
  
  /* db-cardstyle-2 start */
  .db-cardstyle-2 {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .db-cardstyle-2 .db-card-header {
    padding: 1.875rem 1rem 1rem 1rem;
    display: flex;
    align-items: flex-start;
  }
  
  .db-cardstyle-2 .db-card-header .left {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 85%;
  }
  
  .db-cardstyle-2 .db-card-header .left .icon {
    width: 3.25rem;
    height: 3.25rem;
    border-radius: 50%;
    background: var(--gray-300);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .db-cardstyle-2 .db-card-header .left .icon img {
    height: 40%;
  }
  
  .db-cardstyle-2 .db-card-header .left h3 {
    color: var(--black);
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    width: 70%;
  }
  
  .db-cardstyle-2 .db-card-header .dropdown {
    margin-left: auto;
  }
  
  .db-cardstyle-2 .db-card-content {
    padding: 1rem;
  }
  
  .db-cardstyle-2 .db-card-content p {
    font-size: 0.875rem;
    line-height: 1.75rem;
    color: var(--gray-600);
  }
  
  .db-cardstyle-2 .db-card-footer {
    padding: 1rem;
    margin-top: auto;
  }
  
  /* db-cardstyle-2 end */
  
  .flagwithcont {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .flag {
    width: 1.875rem;
    height: 1.4rem;
    overflow: hidden;
    display: inline-flex;
    border-radius: 0.288rem;
  }
  
  .flag img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* footer-area start */
  .footer-area {
    background: var(--blue);
    padding: 2.125rem 0;
  }
  
  .footer-logo {
    height: 100%;
    display: flex;
    align-items: center;
  }
  
  .footer-logo a {
    width: 12.5rem;
    display: inline-flex;
  }
  
  .footer-logo a img {
    width: 100%;
  }
  
  .footer-heading {
    margin-bottom: 1rem;
  }
  
  .footer-heading h4 {
    font-size: 1.25rem;
    font-weight: bold;
    color: var(--White);
  }
  
  .footer-quick-links {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .footer-quick-links li {
    font-size: 0.875rem;
    line-height: 0.875rem;
    margin-bottom: 0.625rem;
    font-weight: 500;
    position: relative;
    padding-left: 1rem;
  }
  
  .footer-quick-links li::after {
    position: absolute;
    left: 0;
    top: 0.313rem;
    z-index: 1;
    content: "";
    width: 0.5rem;
    height: 0.5rem;
    
    background: url('data:image/svg+xml,<svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.09878 3.70174L0.765573 0.0351269C0.631903 -0.0315439 0.469906 -0.00154439 0.369251 0.110126C0.268581 0.221452 0.254925 0.385793 0.335252 0.512447L2.55454 4.00006L0.335252 7.48768C0.254925 7.61435 0.268581 7.77867 0.369251 7.89C0.433922 7.962 0.524577 8 0.616575 8C0.667246 8 0.718246 7.98867 0.765573 7.965L8.09878 4.29839C8.21178 4.24172 8.28311 4.12639 8.28311 4.00006C8.28311 3.87374 8.21178 3.7584 8.09878 3.70174Z" fill="white"/></svg>') center center no-repeat;
  }
  
  .footer-quick-links li a, span {
    color: var(--White);
    cursor: pointer;
  }
  
  .footer-quick-links li:last-child {
    margin-bottom: 0;
  }
  
  .footer-contact {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .footer-contact li {
    font-size: 0.875rem;
    line-height: 0.875rem;
    margin-bottom: 0.625rem;
    font-weight: 500;
    position: relative;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .footer-contact li span {
    width: 1rem;
    height: 1rem;
  }
  
  .footer-contact li span img {
    width: 100%;
  }
  
  .footer-contact li a {
    color: var(--White);
  }
  
  .footer-contact li:last-child {
    margin-bottom: 0;
  }
  
  .app-store {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .app-store a {
    width: 12.313rem;
    display: inline-block;
  }
  
  /* footer-area end */
  
  
  /* Pagination start 02-4-2024 */
  
  
  .pagination-area { 
    margin: 0rem;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
  }
  
  .pagination-area p {
    font-family: "Poppins", sans-serif;
    font-size: 0.625rem !important;
    font-weight: 400;
    line-height: 0.875rem !important;
    text-align: left !important;
    color: #333333;
  }
  .pagination{
    gap: 0.375rem;
    margin: 0;
  }
  .page-link:focus {
    box-shadow:none;
    background-color: initial; 
  }
  .page-item a{
    background-color: #F5F5F5;
    border: none;
  }
  .page-link:hover {
    color: #ffffff !important;
    background-color: #339EF7 !important;
  }
  .page-link:hover span img{
    filter: brightness(0) invert(1);
  }
  .pagination-area a{
    color: #333333;
  }
  
  .table-striped{
    margin: 0;
  }
  
  .header-store-main-content-wrap {
    display: flex;
    justify-content: end;
    flex-grow: 1;
    margin-right: 10px;

    .store-img-wrap {
      width: 100%;
      max-width: 130px;
    }
  }


  .footer-heading-link {
    display: flex;
    justify-content: center;
  }

  .stat-card-wrap {
    .py-10 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  h1 {
    font-size: 24px !important;
    min-height: 28.79px;
  }

  .description-card-wrap {
    margin-left: 10px !important;
  }

  .svg-icon.svg-icon-3x svg {
    height: 2rem !important;
    width: 2rem !important;
  }

  .my-2 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .mt-5 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .fs-6 {
    font-size: 0.9rem !important;
  }
}

.dashboard-card-wrap {
  .col-xl-3 {
    width: 20%;
  }
}

.domestic-card-wrap {
  div {
    div {
      flex: 0 0 auto;
      width: 83.33333333%;
    }
  }
}

.footer-content-wrap {
  width: 20% !important;
}

  @media screen and (max-width: 991px) {

    .footer-second-link {
      margin-top: 2.4rem;
    }

    .dashboard-card-wrap {
      .col-xl-3 {
        width: 50%;
      }
    }

    .footer-content-wrap {
      width: 30% !important;
      justify-content: space-evenly;
    }

    html {
      font-size: initial;
    }
  
    .db-card-content {
      overflow: scroll auto;
    }
  
    .table-inner::-webkit-scrollbar-thumb {
      background: var(--blue);
    }
  
    .table-inner::-webkit-scrollbar {
      height: 0.313rem;
    }
  
    .app-store img {
      width: 90%;
    }
  
    .map {
      height: 100%;
    }

    .nav-border-wrap {
      border: 0.093rem solid var(--gray-300) !important;
    }

    .navbar-toggler:focus {
      box-shadow: none;
    }
    .header-button-area button {
      width: 100%;
    }
    .header-button-area {
      display: flex;
      gap: 1rem;
      margin-top: 0.938rem;
      flex-direction: column;
    }
    .table-inner {
      overflow: scroll auto;
    }
    .table-striped {
      width: 62.5rem;
      margin: 0;
    }
    .footer-contact{
      margin-top: 1rem;
    }
  }
  
  @media screen and (max-width: 682px) {
    .footer-content-wrap {
      width: 100% !important;
      display: flex;
      justify-content: center;
      margin-bottom: 15px;
    }

    .blog-mobile-content {
      margin-bottom: 0px !important;
    }

    .footer-heading {
      display: flex;
      justify-content: center;
    }

    .footer-second-link {
      margin-top: 2.4rem;
    }

    .dashboard-card-wrap {
      .col-xl-3 {
        width: 100%;
      }
    }

    .footer-heading-link {
      display: flex;
      justify-content: center;
    }

    .app-img-wrap {
      width: 100%;
    }

    .header-store-main-content-wrap {
      display: flex;
      justify-content: end;
      flex-grow: 1;
      margin-right: 10px;
  
      .store-img-wrap {
        width: 100%;
        max-width: 95px;
      }
    }

    html {
      font-size: initial;
    }
  
    .table-inner {
      overflow: scroll auto;
    }
  
    .table-inner::-webkit-scrollbar-thumb {
      background: var(--blue);
    }
  
    .table-inner::-webkit-scrollbar {
      height: 0.313rem;
    }
  
    .Vehicle-buyselling-area-item {
      flex-direction: column;
    }
  
    .Vehicle-buyselling-info {
      width: 100%;
      gap: 0rem;
      justify-content: center;
    }
  
    .footer-heading h4,
    .app-store {
      margin-top: 1.25rem;
      gap: 1.125rem;;
    }
  
    .Vehicle-buyselling-button-area {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: initial;
    }
  
    .header-button-area {
      display: flex;
      gap: 1rem;
      margin-top: 0.938rem;
      flex-direction: column;
    }
  
    .header-button-area button {
      width: 100%;
    }
  
    .Vehicle-buyselling-img {
      width: 100%;
      height: auto;
    }
  
    .Vehicle-buyselling-info li {
      width: 100%;
    }
  
    .Vehicle-buyselling-info li:nth-last-child(1),
    .Vehicle-buyselling-info li:nth-last-child(2),
    .Vehicle-buyselling-info li:nth-last-child(3) {
      border-bottom: 0.063rem solid var(--gray-300);
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
    }
  
    .map {
      height: 100%;
    }

    .nav-border-wrap {
      border: 0.093rem solid var(--gray-300) !important;
    }

    .navbar-toggler[aria-expanded="true"] {
      background-color: #B5B5C3 !important;
      
      .navbar-toggler-icon i {
        color: #474242;
      }
    }

    .navbar-toggler:focus {
      box-shadow: none;
    }
    .table-striped {
      width: 62.5rem;
      margin: 0;
    }
    .pagination-area {
      flex-direction: column;
    }
    .pagination-text p{
      margin-bottom: 0.8rem;
  
    }
  }
  
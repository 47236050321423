.admin-edit-company-wrap {
    width: 50% !important;
}

.admin-details-company-wrap {
    width: 50% !important;
}

.filter-wrap-content-company {
    padding-right: 25px !important;
}

@media screen and (max-width: 950px) {
    .admin-edit-company-wrap {
        width: 100% !important;

        .freightPost-popup-container {
            width: 100%;
            position: unset;
            padding-left: 0;
        }
    }

    .admin-details-company-wrap {
        width: 100% !important;

        .freightPost-popup-container {
            width: 100%;
            position: unset;
            padding-left: 0;
        }
    }
}
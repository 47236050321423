.cardMain {
  box-shadow:
          0px 1.3px 2.2px rgba(0, 0, 0, 0.02),
          0px 3.2px 5.3px rgba(0, 0, 0, 0.028),
          0px 6px 10px rgba(0, 0, 0, 0.035),
          0px 10.7px 17.9px rgba(0, 0, 0, 0.042),
          0px 20.1px 33.4px rgba(0, 0, 0, 0.05),
          0px 48px 80px rgba(0, 0, 0, 0.07)
;
}

.advert-detail-modal-container {
  font-family: Poppins, Helvetica, 'sans-serif';
  height: 100%;
  width: 100%;

  h6 {
    font-size: 13px;
    font-weight: 500;
  }

  b {
    color: #1E1E2D;
    font-weight: 600;
  }

  .detail-modal-scroll-container {
    margin-top: 40px;
    height: 92%;
    width: 100%;
  }

}

.countryWrap {
  & > span {
  padding-right: 10px;
  }
}

.filter-match-search-wrap {
  width: 20% !important;
}

.create-container-currency-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px !important;
}

.attachment-Wrap {
  .detailed-list-main {
    max-height: 595px;
  }
}
.price-input-wrap {
  text-align: end;
}

.sub-table-icon {
  .mh-50px {
    width: 50px !important;
    height: 25px !important;

    &:hover {
      color: #009EF7
    }
  }
}

.sub-table-column-wrap {
  display: flex;
  justify-content: center;
}

.transporter-table-height {
  min-height: 350px;
}

.transporter-select-area {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 300px;
  padding: 20px;

  .transporter-select-item {
    box-shadow: 0px 0.2px 2.2px rgba(0, 0, 0, 0.02),
    0px 0.5px 5.3px rgba(0, 0, 0, 0.028),
    0px 0.9px 10px rgba(0, 0, 0, 0.035),
    0px 1.6px 17.9px rgba(0, 0, 0, 0.042),
    0px 2.9px 33.4px rgba(0, 0, 0, 0.05),
    0px 7px 80px rgba(0, 0, 0, 0.07);
    padding: 15px;
    border-radius: 5px;
    background-color: #fff;
    transition: .2s;
    max-height: 170px;
    cursor: pointer;
    min-width: 50vw;

    .txt-ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .left-area {

      .img-area {
        border-radius: 50%;
        overflow: hidden;
        width: 100px;
        height: 100px;

        .transporter-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

      }
    }

    &.active {
      background-color: rgba(248, 214, 195, 0.25);
      box-shadow: none;
    }

    &:hover {
      transition: .2s;
      background-color: rgba(190, 189, 189, 0.25);
    }

  }

  .transporter-select {
    padding-right: 15px;
  }

  .transporter-select-arrow {
    width: 15px;
    height: 15px;
    position: absolute;
    right: 10px;
    top: 43px;
  }
}

.admin-container-details {
  width: 50% !important;
}

.admin-create-transport-wrap {
  width: 50% !important;
}

.admin-create-proposal-container {
  width: 50% !important;
}

.admin-attach-freight-to-container {
  width: 50% !important;
}

.admin-show-proposal-table-wrap {
  width: 50% !important;
}

.admin-show-freight-proposal-table-wrap {
  width: 50% !important;
}

.admin-edit-proposal-wrap {
  width: 50% !important;
}

.admin-edit-freight-proposal-wrap {
  width: 50% !important;
}

.admin-freight-proposal-container-wrap {
  width: 50% !important;
}

@media screen and (max-width: 950px) {
  .admin-container-details {
    width: 100% !important;

    .advert-detail-modal-container {
      height: auto;
    }
  }

  .admin-create-transport-wrap {
    width: 100% !important;
  }

  .admin-create-proposal-container {
    width: 100% !important;

    .freightPost-popup-container {
      width: 100%;
      position: unset;
      padding-left: 0;
    }
  }

  .admin-attach-freight-to-container {
    width: 100% !important;
  }

  .admin-show-proposal-table-wrap {
    width: 100% !important;

    .freightPost-popup-container {
      width: 100%;
      position: unset;
      padding-left: 0;
    }
  }

  .admin-show-freight-proposal-table-wrap {
    width: 100% !important;

    .freightPost-popup-container {
      width: 100%;
      position: unset;
      padding-left: 0;
    }
  }

  .admin-edit-proposal-wrap {
    width: 100% !important;

    .freightPost-popup-container {
      width: 100%;
      position: unset;
      padding-left: 0;
    }
  }

  .admin-edit-freight-proposal-wrap {
    width: 100% !important;

    .freightPost-popup-container {
      width: 100%;
      position: unset;
      padding-left: 0;
    }
  }

  .admin-freight-proposal-container-wrap {
    width: 100% !important;

    .freightPost-popup-container {
      width: 100%;
      position: unset;
      padding-left: 0;
    }
  }
}




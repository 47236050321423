.advert-detail-modal-container {
  font-family: Poppins, Helvetica, 'sans-serif';
  height: 100%;
  width: 100%;

  h6 {
    font-size: 13px;
    font-weight: 500;
  }

  b {
    color: #1E1E2D;
    font-weight: 600;
  }

  .detail-modal-scroll-container {
    margin-top: 40px;
    height: 92%;
    width: 100%;
  }

}

.create-trailer-side-wrap {
  width: 50% !important;
}

.edit-trailer-side-wrap {
  width: 50% !important;
}

.view-trailer-side-wrap {
  width: 50% !important;
}

.file-trailer-side-wrap {
  width: 50% !important;
}

@media screen and (max-width: 950px) {
  
  .create-trailer-side-wrap {
    width: 100% !important;

    .freightPost-popup-container {
      width: 100%;
      position: unset;
      padding-left: 0;
    }
  }

  .edit-trailer-side-wrap {
    width: 100% !important;

    .freightPost-popup-container {
      width: 100%;
      position: unset;
      padding-left: 0;
    }
  }

  .view-trailer-side-wrap {
    width: 100% !important;

    .freightPost-popup-container {
      width: 100%;
      position: unset;
      padding-left: 0;
    }
  }

  .file-trailer-side-wrap {
    width: 100% !important;

    .freightPost-popup-container {
      width: 100%;
      position: unset;
      padding-left: 0;
    }
  }
}
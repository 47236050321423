.selectMain {
  position: relative;


  .arrDown {
    position: absolute;
    right: 15px;
    top: 15px;
    height: 15px;
    width: 15px;
    opacity: 0.7;
    transition: 0.3s;


    &.disabled {
      opacity: 0.3;
    }
  }

  .arrUp {
    transition: 0.3s;
    transform: rotate(180deg);
    position: absolute;
    right: 15px;
    top: 15px;
    height: 15px;
    width: 15px;
    opacity: 0.7;

    &.disabled {
      opacity: 0.3;
    }
  }
}

.button-wrap {
    width: 28% !important
}

.show-help-side-panel-wrap {
    width: 30% !important;
}

@media screen and (max-width: 950px) {
    .show-help-side-panel-wrap {
        width: 100% !important;

        .freightPost-popup-container {
            width: 100% !important;
            position: unset;
            margin-left: 0;
        }
    }

    .buton-mobile-wrap {
        padding: 10px !important;
        font-size: 10px !important;
    }
}
.notifi-container-item-main {
  transition: .3s;

  &:hover {
    border-radius: 10px;
    padding: 0 10px;
    transition: .3s;
    background: rgba(0, 0, 0, 0.04);
  }
}

.elipsis-wrap-contain {
  display: block;
  width: 80%;
}

.advert-detail-modal-container {
  font-family: Poppins, Helvetica, 'sans-serif';
  height: 100%;
  width: 100%;

  h6 {
    font-size: 13px;
    font-weight: 500;
  }

  b {
    color: #1E1E2D;
    font-weight: 600;
  }

  .detail-modal-scroll-container {
    margin-top: 40px;
    height: 92%;
    width: 100%;
  }

}

.edit-address-wrap {
  width: 75% !important;
}

@media screen and (max-width: 950px) {
  .edit-address-wrap {
    width: 100% !important;

    .map-container {
      flex-direction: column;

      .map-container__map {
        margin-bottom: 20px;
      }
    }
  }
}



.advert-detail-modal-container {
  font-family: Poppins, Helvetica, 'sans-serif';
  height: 100%;
  width: 100%;

  h6 {
    font-size: 13px;
    font-weight: 500;
  }

  b {
    color: #1E1E2D;
    font-weight: 600;
  }

  .detail-modal-scroll-container {
    margin-top: 40px;
    height: 92%;
    width: 100%;
  }

}

.text-bold {
  font-weight: 600;
}

.price-input-wrap {
  text-align: end;
}

.freight-advert-show-details {
  width: 50% !important;
}

.freight-advert-edit-wrap {
  width: 50% !important;
}

@media screen and (max-width: 950px) {
  .freight-advert-show-details {
    width: 100% !important;
  }

  .freight-advert-edit-wrap {
    width: 100% !important;
  }
}

.detailed-list-main {
  border-radius: 10px;
  background-color: #FFFFFF;
  box-shadow: 0 16px 32px 0 rgba(66, 66, 66, 0.21);
  border: solid 1px rgba(255, 255, 255, 0.7);
  padding: 15px;
  width: 100%;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;

  .item {
    margin: 10px auto;
  }
}

.active-danger-block  > div {
    border: 5px solid #F1416C
}

.active-warning-block > div{
    border: 5px solid #FFC700
}

.active-success-block > div {
    border: 5px solid #50CD89
}

.carrier-dashboard-show-wrap { 
    width: 57% !important
}

@media screen and (max-width: 950px) {
    .carrier-dashboard-show-wrap { 
        width: 100% !important
    }
}
.advert-detail-modal-container {
  font-family: Poppins, Helvetica, 'sans-serif';
  height: 100%;
  width: 100%;

  h6 {
    font-size: 13px;
    font-weight: 500;
  }

  b {
    color: #1E1E2D;
    font-weight: 600;
  }

  .detail-modal-scroll-container {
    margin-top: 40px;
    height: 92%;
    width: 100%;
  }

}

.price-input-wrap {
  text-align: end;
}

.advert-details-modal-button-close {
  justify-content: center;
}

.text-bold {
  font-weight: 600;
}

.proposal-wrap {
  color: #0000EE;
  cursor: pointer;
}

.freight-details-wrap {
  width: 50% !important;
}

.admin-edit-freight-wrap {
  width: 50% !important;
}

.admin-freight-proposal-create {
  width: 50% !important;
}

.admin-show-proposal-table-wrap {
  width: 50% !important;
}

.admin-edit-freight-proposal-wrap {
  width: 50% !important;
}

.admin-freightPost-popup-container {
  width: 48% !important;
}

@media screen and (max-width: 950px) {

  .editFromWrap {
    margin-bottom: 15px;
  }

  .editToWrap {
    margin-bottom: 15px;
  }

  .freight-details-wrap {
    width: 100% !important;
  }

  .admin-edit-freight-wrap {
    width: 100% !important;
  }

  .admin-freightPost-popup-container {
    width: 100% !important;

    .freightPost-popup-container {
      width: 100%;
      position: unset;
      padding-left: 0;
    }
  }

  .admin-freight-proposal-create {
    width: 100% !important;

    .freightPost-popup-container {
      width: 100%;
      position: unset;
      padding-left: 0;
    }
  }

  .admin-show-proposal-table-wrap {
    width: 100%;

    .freightPost-popup-container {
      width: 100%;
      position: unset;
      padding-left: 0;
    }
  }

  .admin-edit-freight-proposal-wrap {
    width: 100%;

    .freightPost-popup-container {
      width: 100%;
      position: unset;
      padding-left: 0;
    }
  }
}

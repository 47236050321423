.table-container {
  width: 100%;
  overflow-x: auto;

  .table-advanced {
    width: 100%;
    font-weight: 500;

    thead {
      background-color: #f1f1f1;

      th {
        padding: 20px 20px 20px 5px;

        &:first-child {
          border-top-left-radius: 10px;
          padding-left: 20px;
        }

        &:last-child {
          border-top-right-radius: 10px;
        }
      }

    }

    tbody {
      background-color: #FFFFFF;

      tr {
        &:hover {
          background-color: #f7f9fa;
        }

        td {
          padding: 10px 5px;
          border-bottom: 1px solid #ecf0f2;

          &:first-child {
            padding-left: 20px;
          }

          .edit-delete-button {
            border: 0;
            background: 0;
            opacity: 0;
          }

          .save-btn {
            display: block;
          }
        }

        &:hover {
          td {
            .edit-delete-button {
              opacity: 1;
            }
          }
        }
      }
    }


  }
}

.sub-table-no-data {
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
}

.action-td {
  min-width: 120px;
}
.pagination {
  background-color: #FFFFFF;
  font-family: 'Poppins', sans-serif;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 8px;

  .pagination-buttons {
    .pag-btn {
      background-color: transparent;
      border: none;

      .previous-arrow {
        svg {
          fill: #5f6b76;
          transform: rotate(180deg);
        }
      }
    }
  }


  .page-of {
    color: #5f6b76;

    strong {
      color: black;
    }
  }

  .go-to-page {
    color: #5f6b76;
    display: flex;
    align-items: center;

    .go-to-textbox {
      margin-left: 10px;
      border-radius: 10px;
    }
  }

  .show-on-page {
    display: flex;
    align-items: center;
    color: #5f6b76;

    .select-inp {
      margin-left: 10px;
      margin-right: 10px;
      border-radius: 10px;
    }
  }
}

.selected-list-item-row {
  background-color: rgba(130, 129, 129, 0.11) !important;
  border: 2px dashed #0BB7AF !important;
}

.img-advert-table-content-wrap {
  width: 100%;
    height: 100%;
}

.img-advert-table-wrap {
  width: 100%;
    height: 100%;
    max-height: 300px;
}

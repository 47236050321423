.groupMain {
  border-radius: 5px;
  border: solid #d9d6d6 1px;
  font-family: 'Poppins', sans-serif;
  width: 100%;
  height: 100%;
  padding: 5px;
  overflow: hidden;

  .scrollArea {
    height: 95%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 5px;

    font-weight: 500;
    color: #A7ABBB;
    font-size: 14px;

    .checkArea {

      .inp {

      }

      .label {
        font-weight: 500;
        font-size: 14px;
      }
    }
  }

}

.advert-preview-modal-container {
  font-family: Poppins, Helvetica, 'sans-serif';
  height: 100%;
  width: 100%;

  h6 {
    font-size: 13px;
    font-weight: 500;
  }

  b {
    color: #1E1E2D;
    font-weight: 600;
  }

  .preview-modal-scroll-container {
    padding-top: 30px;
  }

}

.text-bold {
  font-weight: 600;
}

.price-input-wrap {
  text-align: end;
}

.map-wrap {
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
}

.main-error-wrap {
  font-size: 1.2rem;
  font-weight: bold;
}

.or-wrap {
  font-size: 1.3rem !important;
  font-weight: bold !important;
  text-decoration: underline;
}

.active-tab-wrap {
  border-bottom: 1px solid #3699FF !important;
}

.nav-normal-wrap {
  color: black !important;
}

.nav-main-wrap {
  display: block;
  padding: 0.5rem 1rem;
}

.create-new-freight-advert-wrap {
  width: 58% !important;
}

@media screen and (max-width: 950px) {
  .create-new-freight-advert-wrap {
    width: 100% !important;
  }
}
.map-container {
    display: flex;
    justify-content: center;
    padding: 1rem;
  
    &__map {
      height: 70vh;
      width: 100%;
    }
}

.form-display-wrap {
    padding-left: 12px;
}

.create-new-address-wrap {
  width: 75% !important;
}

@media screen and (max-width: 950px) {
  .create-new-address-wrap {
    width: 100% !important;

    .map-container {
      flex-direction: column;

      .map-container__map {
        margin-bottom: 20px;
      }
    }
  }
}
.transporter-popup-container {
    bottom: 0;
    position: absolute;
    display: flex;
    flex-direction: row;
    width: 48%;
}

.create-update-new-transport-wrap {
    width: 50% !important;
}

@media screen and (max-width: 950px) {
    .create-update-new-transport-wrap {
        width: 100% !important;

        .transporter-popup-container {
            position: unset;
            width: 100%;
        }
    } 
}
.advert-preview-modal-container {
  font-family: Poppins, Helvetica, 'sans-serif';
  height: 100%;
  width: 100%;

  h6 {
    font-size: 13px;
    font-weight: 500;
  }

  b {
    color: #1E1E2D;
    font-weight: 600;
  }

  .preview-modal-scroll-container {
    padding-top: 30px;
  }

}

.text-bold {
  font-weight: 600;
}

.price-input-wrap {
  text-align: end;
}

.tab-wrapper {
  font-size: 20px; 
  cursor: pointer;
}

.border-wrap {
  border-top: 4px solid #EFF2F5;
  border-bottom: 5px solid #EFF2F5;
  border-left: 5px solid #EFF2F5;
  border-right: 5px solid #EFF2F5;
}

.create-quick-freight-wrap {
  width: 58% !important;
}

@media screen and (max-width: 950px) {
  .create-quick-freight-wrap {
    width: 100% !important;
  } 
}
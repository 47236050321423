.day-picker-main-area {
  position: relative;
  font-family: Poppins, sans-serif;
  z-index: 50;

  .select-input-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    .select-date-icon{
      margin-left: 15px;
    }
  }

  .select-menu-area {

    position: absolute;
    border-radius: 10px;
    background-color: #FFFFFF;
    left: 0;
    top: 50px;
  }


}

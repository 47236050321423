.bg-main-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 50px;
    & > h1 {
        color: #757575;
        font-size: 72px;
        line-height: 84px;
    }
}

.privacy-boy-wrap {
    width: 100%;
    background: #edf1f8;
    padding: 20px;
    display: flex;
    justify-content: center;
    .privacy-body-content {
        max-width: 922px;
    }

    .privacy-content-first {
        text-align: left;
        color: #757575;
        margin: 1.5em 0 0.5em !important;
        font-weight: bold;
        font: 24px Roboto, sans-serif;
        font-weight: bold;
    }

    .privacy-point-content-wrap {
        text-align: left;
        margin-top: 1.5rem !important;
    }

    .privacy-point-content-wrap-second {
        text-align: left;
        margin-top: 0.8rem !important;
    }

    .privacy-point-content-wrap-color {
        color: #757575;
        font: 18px Roboto, sans-serif;
        font-weight: bold;
    }

    .privacy-footer-quick-links {
        margin: 0;
        padding: 0;
        list-style-type: circle;
      }
      
      .privacy-footer-quick-links li {
        margin-bottom: 0.625rem;
        position: relative;
        padding-left: 1.5rem;
      }
      
      .privacy-footer-quick-links li::after {
        background: #757575;
        content: "";
        list-style-type: circle;
        position: absolute;
        left: 0;
        top: 0.313rem;
        width: 0.7rem;
        height: 0.7rem;
      }
      
      .privacy-footer-quick-links li a, span {
        color: var(--White);
        cursor: pointer;
      }
      
      .privacy-footer-quick-links li:last-child {
        margin-bottom: 0;
      }

      .privacy-sub-point-color {
        color: #757575;
        font: 16px Roboto, sans-serif;
        font-weight: bold;
    }
}

@media screen and (max-width: 682px) {
  .bg-main-content > h1 {
    font-size: 50px;
  }
}
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;700&family=Poppins:wght@300;400;700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: 'Kanit', sans-serif;
  background: #e6e6e6;
}

ul li {
  list-style: none;
}

.app {
  width: 100vw;
  overflow: hidden;
}

a {
  color: #d3e7eb;
  text-decoration: none;
}


.create-client-wrap {
    width: 50% !important;
}

.edit-client-wrap {
    width: 50% !important;
}

.view-client-wrap {
    width: 50% !important;
}

@media screen and (max-width: 950px) {
    .create-client-wrap {
        width: 100% !important;

        .freightPost-popup-container {
            width: 100%;
            position: unset;
            padding-left: 0;
        }
    }

    .edit-client-wrap {
        width: 100% !important;

        .freightPost-popup-container {
            width: 100%;
            position: unset;
            padding-left: 0;
        }
    }

    .view-client-wrap {
        width: 100% !important;

        .freightPost-popup-container {
            width: 100%;
            position: unset;
            padding-left: 0;
        }
    }
}
.no-data-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    & > h1 {
        font-weight: bold;
        font-size: 2.5rem;
    }
}

.db-area-content-wrap {
    padding-left: 20px !important;
    overflow: auto;
    padding-right: 20px !important;
    padding-top: 7rem !important;
}
.file-review-view-wrap {
    width: 50% !important;
}

.driver-selected {
    background-color: #50CD89 !important;
    color: #000000 !important;
}

.trailer-selected {
    color: #000000 !important;
    background-color: #FFC700 !important;
}

.vehicle-selected {
    color: #000000 !important;
    background-color: #F1416C !important;
}

.listing-selected {
    color: #000000 !important;
    background-color: #009EF7 !important;
}

@media screen and (max-width: 950px) {
    .file-review-view-wrap {
        width: 100% !important;
    }
}
.bg-photo-overlay {
    background: rgba(0, 0, 0, .26);
    background-size: cover;
    height: 480px;
    position: absolute;
    width: 100%;
    z-index: 2;
}

.page-body {
    position: relative;
    z-index: 20;
    .centered-wrap-main {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        max-width: 922px;
        min-height: 100vh;
        padding: 24px 0;

        .centered-top-container {
            padding: 0 16px;
            width: fit-content;

            .centered-top {
                position: relative;

                .blog-name {
                    display: flex;
                    flex-wrap: wrap;
                    margin: 0 48px 27px 48px;
                    box-sizing: border-box;
                    margin-left: 36px;
                    min-height: 48px;
                    opacity: 1;
                    padding-top: 12px;

                    .section {
                        margin-bottom: 5px;
                        margin-right: 15px;

                        h1 {
                            font-size: 24px !important;
                            line-height: 24px !important;
                            margin: 0 !important;
                            text-align: left;
                            color: #ffffff;
                            font: bold 45px Roboto, sans-serif;
                            width: 100%;

                            a {
                                color: #ffffff;
                                text-decoration: none;
                                background: transparent;
                            }
                        }
                    }
                }
            }
        }

        .centered-bottom {
            padding: 0 16px;
            display: block;

            .main {
                outline: 0;

                .widget {
                    margin-bottom: 16px;

                    .post-start-content-head-wrap {
                        margin-bottom: 5px;
                        font-size: 15px;
                    }

                    .post-outer-container {
                        background: #ffffff;
                        min-height: 40px;
                        padding: 30px 40px;
                        width: auto;
                        display: block;

                        .post-outer {
                            border: 0;
                            position: relative;
                            padding-bottom: .25em;

                            .post-title {
                                color: #212121;
                                font: bold 22px Roboto, sans-serif !important;
                                float: left;
                                margin: 0 0 8px 0 !important;
                                max-width: calc(100% - 48px);
                            }

                            .post-header {
                                clear: left;
                                color: rgba(0, 0, 0, 0.54);
                                margin: 0;
                                width: inherit;

                                .byline {
                                    color: rgba(0, 0, 0, 0.54);
                                    display: inline-block;
                                    line-height: 24px;
                                    margin-top: 8px;
                                    vertical-align: top;
                                }

                                .byline:last-child {
                                    margin-right: 0;
                                }
                            }

                            .post-body {
                                color: #757575;
                                font: 15px Roboto, sans-serif;
                                line-height: 1.6em;
                                margin: 1.5em 0 2em 0;
                                display: block;

                                .point-content-wrap {
                                    text-align: left;
                                    margin-top: 1.5rem !important;
                                }

                                .point-content-wrap-second {
                                    text-align: left;
                                    margin-top: 0.8rem !important;
                                }

                                .point-content-wrap-color {
                                    color: #757575;
                                    font: 18px Roboto, sans-serif;
                                    font-weight: bold;
                                }

                                .point-content-wrap-color-sub {
                                    color: #757575;
                                    font: 16px Roboto, sans-serif;
                                    font-weight: bold;
                                }

                                .point-content-wrap-sub {
                                    margin-left: 1rem !important;
                                }

                                .img-road-wrap {
                                    text-align: left;
                                    color: #757575;
                                    margin: 1.5em 0 0.5em !important;
                                    font-weight: bold;
                                    font: 24px Roboto, sans-serif;
                                    font-weight: bold;
                                    .separator-wrap {
                                        clear: both;
                                        text-align: center;

                                            .improved-img-wrap {
                                                max-width: 100%;
                                                height: inherit;
                                            }
                                    }

                                }
                            }

                            .post:first-child {
                                margin-top: 0;
                            }
                        }
                    }

                    .post-outer-container:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
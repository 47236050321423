.preview-success-container-user-page {
  height: 100%;
  width: 100%;

  .preview-modal-scroll-container {
    padding-top: 50px;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 98%;
    width: 100%;
  }

}

.success-image-container {

  .success-image {
    max-width: 300px;
    max-height: 250px;
    margin: auto;

    img {
      width: 100% !important;
      height: 100% !important;
    }
  }
}


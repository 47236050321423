.detailed-list-item-main {
  border-radius: 10px;
  background-color: #FFFFFF;
  box-shadow: 0 16px 32px 0 rgba(66, 66, 66, 0.21);
  border: solid 1px rgba(255, 255, 255, 0.7);
  padding: 0 15px;
  width: 100%;
  border-left: #0BB783 solid 2px;
  cursor: pointer;
  transition: .3s;

  &:hover {
    background-color: rgba(148, 147, 147, 0.11);
    transition: .3s;
  }

  &.active {
    background-color: rgba(130, 129, 129, 0.11);
    border: 3px dashed #0BB7AF;
  }

  .title {
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
  }

  .elipsis-wrap-detail-contain {
    display: block;
  }

  .inside-content-wrap {
    width: 20% !important;
  }

}

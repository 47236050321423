.image-main-wrap {
    position: relative;
}

.img-advert-content-wrap {
    width: 100%;
    height: 100%;
    max-height: 300px;
}

.img-advert-wrap {
    width: 100%;
    height: 100%;
}

.overlay-text {
    position: absolute;
    bottom: 0;
    right: 0;
    background: #474b5e;
    color: white;

    & > span {
        font-size: 12px;
        font-weight: 600;
    }
}

@media screen and (max-width: 950px) {
    .action-widget-wrap {
        display: flex;
        justify-content: center;
    }   
}
.main-content-wrappper {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 0.7rem;
}

::-webkit-scrollbar-thumb {
  background: #c0c6cd !important ;
  border-radius: 5px;
}

.top-btn-wrap {
  margin-left: 10px;
}

.map-wrap-main-content {
  max-width: 1490px !important;
}

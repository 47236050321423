.inp {
  position: relative;
  border: 1px solid #E4E6EF;
  border-radius: 0.475rem;
  .searchIcon {
    position: absolute;
    left: 10px;
    top: 26%;
  }
}

.carrier-search-document-wrap {
    width: 50% !important;
}

@media screen and (max-width: 950px) {
    .carrier-search-document-wrap {
        width: 100% !important;

        .freightPost-popup-container {
            width: 100%;
            position: unset;
            padding-left: 0;
        }
    }
}
.forward-page-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 100%;

    .forward-preview-wrap {
        display: flex;
        grid-column-gap: 10px;
        column-gap: 10px;
        align-items: center;
    }
}
.multi-range-wrap > .bar > .bar-inner {
    border: none !important;
    box-shadow: none !important;
}

.multi-range-wrap > .bar > .bar-left {
    border: none !important;
    box-shadow: none !important;
}

.multi-range-wrap > .bar > .bar-right {
    border: none !important;
    box-shadow: none !important;
}

.multi-range-wrap > .bar > .thumb::before {
    border: none !important;
    box-shadow: 0 1px 1px rgba(0,0,0,.5) !important;
}

.multi-range-wrap > .bar > .thumb > .caption > .min-caption {
    background-color: #A1A5B7;
}

.multi-range-wrap > .bar > .thumb > .caption > .max-caption {
    background-color: #A1A5B7;
}

.brand-list-wrap {
    background-color: white;

    .searchWrapper {
        border: 1px solid #E4E6EF;
    }
}

.carousal-wrap {
    .carousel-slider {
        max-height: 270px;
    }

    .control-next.control-arrow:before {
        border-left: 8px solid #181616;
    }

    .control-prev.control-arrow:before {
        border-right: 8px solid #181616;
    }

    .carousel.carousel-slider .control-arrow:hover {
        background: none;
    }
}

.modal-carousal-wrap {
    .carousel .control-prev.control-arrow:before {
        border-right: 30px solid #fff;
    }

    .carousel .control-next.control-arrow:before {
        border-left: 30px solid #fff;
    }

    .carousel.carousel-slider .control-arrow:hover {
        background: none;
    }

    .carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
        margin: 0 40px;
        display: inline-block;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        content: '';
    }
}

.details-posting-wrap {
    color: #009ef7;
}
.carrier-create-document-wrap {
    width: 50% !important;
}

.carrier-edit-document-wrap {
    width: 50% !important;
}

.carrier-view-document-wrap {
    width: 50% !important;
}

.carrier-upload-document-wrap {
    width: 50% !important;
}

@media screen and (max-width: 950px) {
    .carrier-create-document-wrap {
        width: 100% !important;

        .freightPost-popup-container {
            width: 100%;
            position: unset;
            margin-left: 0;
        }
    }

    .carrier-edit-document-wrap {
        width: 100% !important;

        .freightPost-popup-container {
            width: 100%;
            position: unset;
            margin-left: 0;
        }
    }

    .carrier-view-document-wrap {
        width: 100% !important;

        .freightPost-popup-container {
            width: 100%;
            position: unset;
            margin-left: 0;
        }
    }

    .carrier-upload-document-wrap {
        width: 100% !important;

        .freightPost-popup-container {
            width: 100%;
            position: unset;
            margin-left: 0;
        }
    }
}
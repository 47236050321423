
.modalMainBack {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Poppins, Helvetica, sans-serif;
  background-color: rgba(37, 51, 66, 0.2);
  z-index: 107;

  .modalMainContainer {
    position: relative;
    border-radius: 10px;
    box-shadow: 0 16px 32px 0 rgba(66, 66, 66, 0.21);
    border: solid 1px rgba(255, 255, 255, 0.7);
    background-color: #ffffff;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 600px;
    padding: 0 15px 10px 15px;

    @media only screen and (min-width: 320px) and (max-width: 768px) {
      width: 90% !important;
    }

    @media only screen and (min-width: 768px) and (max-width: 1200px) {
      max-width: 70% !important;
    }

    .headBar {
      z-index: 10000;
      position: sticky;
      width: 100%;
      top: 0;
      left: 0;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-end;


      .closeButton {
        border: none;
        background-color: transparent;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
      }

    }

    .bar {
      justify-content: space-between;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      background-color: #FFFFFF;
      box-shadow: 0px 0px 2.2px rgba(0, 0, 0, 0.008),
      0px 0px 5.3px rgba(0, 0, 0, 0.012),
      0px 0px 10px rgba(0, 0, 0, 0.015),
      0px 0px 17.9px rgba(0, 0, 0, 0.018),
      0px 0px 33.4px rgba(0, 0, 0, 0.022),
      0px 0px 80px rgba(0, 0, 0, 0.03);
    }

    .headImg {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 40px;
    }

    .middleTextArea {
      margin-bottom: 40px;

      .middleTitle {
        margin-bottom: 30px;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        color: #253342;
      }

      .middleSubTitle {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: center;
        color: #5f6b76;
      }
    }

    .buttonAreaSingle {
      display: flex;
      align-items: center;
      justify-content: center;

      .leftBtn {

      }

      .rightBtn {
        width: 240px;
      }
    }

    .buttonAreaMultiple {
      display: flex;
      align-items: center;

      .leftBtn {

      }

      .rightBtn {
      }
    }
  }
}

.dropMain {
  font-family: Poppins, sans-serif;
  position: relative;
  width: 100%;
  height: 42px;
  background-color: #fff;
  border-radius: 5px;


  .input {
    font-weight: 500;
    border-radius: 5px;
    height: 100%;
    width: 100%;
    padding: 0 20px;
    padding-right: 33px;
    background-color: #FFFFFF;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:focus {
      outline: lightgray 1px solid;
    }

    &:disabled {
      background: rgba(206, 204, 204, 0.37);
    }

    @media only screen and (min-width: 1200px) {
      /*border: none !important;*/
    }
  }

  .arrow {
    position: absolute;
    top: 55%;
    right: 10px;
    transform: translate(0, -50%);
    transition: 0.3s;
  }

  .arrowUp {
    transition: 0.3s;
    top: 20%;
    transform: rotate(180deg);
  }

  .menu {
    font-weight: 500;
    border-radius: 5px;
    max-height: 350px;
    width: 100%;
    position: absolute;
    top: 50px;
    left: 0;
    background-color: #fff;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 100;
    -webkit-box-shadow: -3px 6px 46px 4px rgba(62, 66, 66, 0.22);
    -moz-box-shadow: -3px 6px 46px 4px rgba(62, 66, 66, 0.22);
    box-shadow: -3px 6px 46px 4px rgba(62, 66, 66, 0.22);

    ul {

      padding: 10px 0;
      height: inherit;
      list-style: none;

      li {
        padding: 15px;
        transition: 0.3s;
        cursor: pointer;

        &:hover {
          transition: 0.3s;
          background-color: rgba(178, 176, 176, 0.39);
        }
      }
    }
  }
}

.price-input-wrap {
    text-align: end;
}

.create-new-container-popup-wrap {
    width: 50% !important;
}

@media screen and (max-width: 950px) {
    .create-new-container-popup-wrap {
    width: 100% !important;
    }
}
.overlay-upload-close-icon {
    position: absolute;
    top: 0;
    right: 0;
    background: #eff2f5;
    color: white;
    border-radius: 50%;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;

    &:hover {
        background: #009EF7;
    }

    & > span {
        font-size: 12px;
        font-weight: 600;
        background: red;
    }
}

.overlay-upload-icon {
  position: absolute;
    bottom: 0;
    right: 0;
    background: #eff2f5;
    color: white;
    border-radius: 50%;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    margin-bottom: 5px;

    & > .btn:hover {
      background-color: #009EF7 !important;
  }

  & > .btn.btn-light:hover:not(.btn-active) {
    background-color: #009EF7 !important;
    color: white !important;
  }
}

.delete-icon-wrap {
    font-size: 32px !important;
}

.upload-pic-wrap {
    position: absolute;
    top: 30%;
    left: 20%
}

.accordian-main-wrap {
    .accordion-button:not(.collapsed) {
        color: #a7b1c2;
        font-weight: bold;
      }
}

.accordian-btn-wrap {
    border-radius: 20px !important;
    background: #dee0e9 !important;

    color: black;
    font-size: 16px !important;
    font-weight: 500 !important;
}

.box-wrap-content {
    border: 1px solid #A1A5b7;
    padding: 7px;
    border-radius: 20px;
}

.contact-deco {
    text-decoration: underline;
}

.delete-icon-wrap {
    &:hover {
        color: #f1416c !important;
        fill: #f1416c !important;
        .svg-icon svg [fill]:not(.permanent):not(g) {
            transition: fill 0.3s ease;
            fill: #f1416c !important;
        }
    }
}

.image-hover-wrap {
  &:hover {
    opacity: 0.8;
  }
}

.modal-image {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.8); /* Black w/ opacity */
  }
  
  /* Modal Content (image) */
  .modal-content-image {
    margin: auto;
    display: block;
    opacity: 1 !important;
    max-width: 1200px;
  }
  
  /* Add Animation */
  .modal-content-image {  
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.6s;
    animation-name: zoom;
    animation-duration: 0.6s;
  }
  
  @-webkit-keyframes zoom {
    from {-webkit-atransform:scale(0)} 
    to {-webkit-transform:scale(1)}
  }
  
  @keyframes zoom {
    from {transform:scale(0)} 
    to {transform:scale(1)}
  }
  
  /* The Close Button */
  .modal-close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }
  
  .modal-close:hover,
  .modal-close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }
.main {
    height: calc(100% -176px);
}

.chat-log {
	height: calc(100% - 5px);
	overflow: auto;
}

.chat-prof-wrap {
    display: flex;
}

.img-circle {
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.chat-log__item {
	background: #fafafa;
	padding: 10px;
	margin: 0 auto 20px;
	max-width: 80%;
	float: left;
	border-radius: 4px;
	box-shadow: 0 1px 2px rgba(0,0,0,.1);
	clear: both;
    margin-left: 10px;
}

.chat-log__item.chat-log__item--own {
	float: right;
	background: #c9e8f9;
	text-align: right;
}

.chat-form {
    border-radius: 40px;
	background: transparent;
	padding: 40px 0;
	bottom: 0;
	width: 100%;
}

.chat-log__author {
	margin: 0 auto .5em;
	font-size: 14px;
	font-weight: bold;
}
.right-layout-popup-layer {
  width: 100%;
  height: 100%;
  z-index: 117;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(8, 8, 8, 0.34);
  display: flex;
  justify-content: flex-end;

  ::-webkit-scrollbar {
    display: block;
    width: 0.7rem;
  }

  ::-webkit-scrollbar-thumb {
    background: #c0c6cd !important ;
    border-radius: 5px;
  }

  .right-layout-popup-main {
    width: 400px;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: #FFFFFF;
    border-radius: 20px 0 0 20px;
    padding: 20px;

    .popup-title {
      font-size: 22px;
      color: #1b1b28;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 950px){
  .right-layout-popup-main {
    border-radius: 0 !important;
  }
}
/*Hide Number Input Arrows Start*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.fv-help-block {
  color: #dc3444
}
/*Hide Number Input Arrows Start*/

.multi-range-wrap > .bar > .bar-inner {
    border: none !important;
    box-shadow: none !important;
}

.multi-range-wrap > .bar > .bar-left {
    border: none !important;
    box-shadow: none !important;
}

.multi-range-wrap > .bar > .bar-right {
    border: none !important;
    box-shadow: none !important;
}

.multi-range-wrap > .bar > .thumb::before {
    border: none !important;
    box-shadow: 0 1px 1px rgba(0,0,0,.5) !important;
}

.multi-range-wrap > .bar > .thumb > .caption > .min-caption {
    background-color: #A1A5B7;
}

.multi-range-wrap > .bar > .thumb > .caption > .max-caption {
    background-color: #A1A5B7;
}

.public-vehicle-main-wrap {
    padding: 40px 80px;
    overflow: auto;
}

.brand-list-wrap {
    background-color: white;

    .searchWrapper {
        border: 1px solid #E4E6EF;
    }
}

.carousal-wrap {
    .carousel-slider {
        max-height: 270px;
    }
}

.details-posting-wrap {
    color: #009ef7;
}
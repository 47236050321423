.company-type-selector-main {
  padding: 15px;

  .selector-outer {
    display: grid;
    place-items: center;

    .selected-type {
      border: 5px solid #009EF7 !important;
      .svg-icon > svg > path {
        fill: #009EF7 !important;
      }
    }

    .selector-inner {
      border: 3px solid #F5F8FA;
      border-radius: 10px;
      padding: 35px;
      transition: .2s;
      cursor: pointer;
      background: #FFFFFF;

      &:hover {
        background: #F5F8FA;
        transition: .2s;
      }


    }
  }
}

.freightPost-popup-container {
    bottom: 0;
    position: absolute;
    display: flex;
    flex-direction: row;
    width: 48%;
}

.table-wrap {
    .table-container {
        max-height: 300px;
    }
}

.create-new-freight-wrap {
    width: 50% !important;
}

@media screen and (max-width: 950px) {
    .create-new-freight-wrap {
        width: 100% !important;

        .create-container-currency-wrap {
            margin-bottom: 10px;
        }

        .freightPost-popup-container {
            width: 100%;
            position: unset;
            padding-left: 0;
        }
    }
}
.overlay-text {
    position: absolute;
    bottom: 0;
    right: 0;
    background: #474b5e;
    color: white;

    & > span {
        font-size: 12px;
        font-weight: 600;
    }
}

.input-checkbox-wrap {
    &.form-check-input:checked {
        background-color: #24ae66 !important;
    }
}

.check-input-label-warp {
    color: #24ae66
}

.detail-font-wrap {
    font-size: small !important;
}

.main-price-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
}

.badge-wrapper-content {
    position: absolute;
    top: 2px;
    right: -5px;
    padding: 5px 10px;
    border-radius: 50%;
    background-color: #009EF7;
    color: white;
    height: 20px;
    display: inline-block;
    width: 20px;
}

.message-icon-wrap svg {
    width: 2.4rem !important;
    height: 2.4rem !important;
}

.overlay-text-new {
    background: #474b5e;
    color: white;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 0.5rem 0.5rem 0.5rem;

    --f: 10px; /* control the folded part*/
    --r: 15px; /* control the ribbon shape */

    line-height: 2.3em;

    right: calc(-0.1*var(--f));
    border-left: var(--r) solid #0000;
    clip-path: 
        polygon(0 0,100% 0,100% calc(100% - var(--f)),calc(100% - var(--f)) 100%,
        calc(100% - var(--f)) calc(100% - var(--f)),0 calc(100% - var(--f)),
        var(--r) calc(50% - var(--f)/2));

    & > span {
        font-size: 12px;
        font-weight: 600;
    }
}

.posting-font-wrap {
    font-weight: 600;
    font-size: 12px;
    color: #009ef7 !important;
}

@media screen and (max-width: 950px) {

    .mobile-image-wrap {
        margin-top: 20px !important;
    }

    .mobile-content-wrap {
        padding: 15px !important;
    }

    .inside-label-content-wrap {
        width: 50% !important;
    }
}
.view-details-freight-popup {
    justify-content: center;
}

.view-details-freight-popup-scroll {
    height: auto !important;
}

.view-freight-details-wrap {
    width: 57% !important;
}

@media screen and (max-width: 950px) {
    .view-freight-details-wrap {
        width: 100% !important;
    }
}
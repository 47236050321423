.time-picker-new-main {
  position: relative;
  font-family: Poppins, sans-serif;
  background-color: transparent;


  .time-input {
    padding: 18px 10px;
    border-radius: 10px;
    background-color: #fff;
    transition: 0.3s;
    border: solid transparent 1px;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #8e9fad;
    display: flex;
    align-items: center;
    width: 100%;

    .time-icon {
      max-height: 24px;
      max-width: 24px;
      display: flex;
      align-items: center;
      margin-left: 10px;
    }

  }

  .time-input-active {
    border-radius: 10px;
    box-shadow: 0 0 0 1px transparent, inset 0 0 0 1px #1e52b6, 0 0 0 4px rgba(30, 82, 182, 0.15);
    transition: 0.3s;
    border: solid #1e52b6 1px;
    color: #253342;
  }

  .time-menu {
    padding: 8px 10px 10px 12px;
    border-radius: 10px;
    box-shadow: 0 8px 20px 0 rgba(66, 66, 66, 0.06);
    border: solid 1px rgba(255, 255, 255, 0.7);
    background-color: #fff;
    position: absolute;
    margin-top: 8px;
    width: 99%;
    height: 188px;
    overflow: hidden;
    z-index: 9;


    ul {
      list-style: none;
      padding: 0;
      width: 100%;
      height: 170px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 8px;
      }


      &::-webkit-scrollbar-track {
        background-color: #F2F5F6;
        border-radius: 100px;
        border: none;
      }

      &::-webkit-scrollbar-thumb {
        background: #8D9FAD;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 100px;
      }


      li {
        margin: auto;
        text-align: center;
        padding-top: 5px;
        padding-bottom: 5px;
        cursor: pointer;

        &:hover {
          background-color: #f9fafb;
        }
      }
    }

  }

  .time-picker-input {

    .react-time-picker__wrapper {
      border: none !important;

      .react-time-picker__inputGroup {
        border: none !important;
      }
    }
  }
}


.my-time-picker-main {
  .react-time-picker__wrapper {
    border: none !important;
  }
}

.time-picker-inp {
  padding: 10px;
  border: none !important;
  border-radius: 5px;
  background-color: #fff;
}

.about-us-wrap {
    padding: 0 100px;

    .side-separator {
        padding: 0 40px;

        & > h1 {
            font-size: 35px;
            margin-bottom: 20px;
        }

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 4px;
            height: 50px;
            background-color: #d31e2f;
        }
    
    }

    .right-panel-image {
        position: relative;
        margin-left: 40px;
        padding-right: 0;
    }
}

.about-us-wrap-content {
    padding-top: 3rem !important;
}

@media screen and (max-width: 950px) {
    .about-us-wrap {
        padding: 0 15px !important;
        flex-direction: column;

        .right-panel-image {
            margin-left: 0;
            margin-top: 20px;
        }
    }

    .image-wrapper {
        display: contents;
    }
}
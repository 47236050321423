
@media screen and (max-width: 950px) {
    .auth-main-wrap {
        overflow: auto !important;

        .auth-main-wrap1 {
            width: 100% !important;
            height: 100% !important;
        }
    }
    .auth-main-content-wrap {
        padding: 0 !important;

        .auth-main-inside-wrap {
            padding: 0 !important;

            .login-main-content-wrap {
                padding: 0 20px 10px !important;
                min-width: auto !important;
            }
        }
    }

    .login-img-wrap {
        display: none;
    }
}
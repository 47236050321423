.tab-manager-main {

    padding-bottom: 20px;
    .tab-manager-title-area {
  
    }
  
    .tab-manager-btn-area {
      display: flex;
      align-items: center;
      justify-content: space-between;
  
    }
  }
  
.loading-component-main-layer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(8, 8, 8, 0.34);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;

  .loading-btn {
    height: 80px;
    width: 80px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    border: 4px solid rgba(0, 0, 0, 0.4);
    border-top-color: #fff;
    border-bottom-color: #fff;
    animation: spinner3 800ms ease infinite;
  }

  @keyframes spinner3 {
    to {
      transform: rotate(360deg);
    }
  }

}

.w-85 {
    width: 85%;
}

.view-freight-popup {
    width: 56%;
}

.menu-button-active {
    color: #009EF7 !important;
}

.carrier-view-freight-exchange-wrap {
    width: 61% !important;
}

.carrier-freight-proposal-list-exchange-wrap {
    width: 50% !important;
}

.carrier-edit-freight-exchange-wrap {
    width: 50% !important;
}

.carrier-show-freight-exchange-wrap {
    width: 50% !important;
}

.carrier-show-proposal-wrap {
    margin-top: -10px !important;
}

@media screen and (min-width: 950px) {
    .filter-wrap-content {
        width: 19.666667% !important;
    }
}

@media screen and (max-width: 950px) {
    .carrier-view-freight-exchange-wrap {
        width: 100% !important;

        .freightPost-popup-container {
            width: 100%;
            position: unset;
            margin-left: 0;
        }
    }

    .carrier-show-proposal-wrap {
        margin-top: 10px !important;
    }

    .radio-wrap {
        padding-left: 35px !important;
    }

    .carrier-freight-proposal-list-exchange-wrap {
        width: 100% !important;

        .freightPost-popup-container {
            width: 100%;
            position: unset;
            margin-left: 0;
        }
    }

    .carrier-edit-freight-exchange-wrap {
        width: 100% !important;

        .freightPost-popup-container {
            width: 100%;
            position: unset;
            margin-left: 0;
        }
    }

    .carrier-show-freight-exchange-wrap {
        width: 100% !important;

        .freightPost-popup-container {
            width: 100%;
            position: unset;
            margin-left: 0;
        }
    }
}